

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL5PostLabPart8',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part5_8: null,
      },
      questions: [
        {
          text: 'Based on your answers for questions 1 and 8, does it make sense to make this piece of equipment out of this material? Why or why not? If not, what is a substance that you would choose to make the piece of equipment from? Explain your answer.',
          inputName: 'part5_8',
        },
      ],
    };
  },
};
